<template>
<div class="text-center relative h-screen overflow-auto">
    <!-- <div v-if="!found" class="mx-8">
        <qrcode-stream @decode="onDecode" @init="onInit" />
        <div class="">
            <div v-if="error">
                <SystemMessage :text="error" type='error' />
            </div>
            <Button :text="$t('qrScan.openBtn')" buttonType="primary" v-on:click="elementCheck('')"/>
        </div>
    </div> -->
    <div id="selfie_filters" class="h-screen overflow-hidden">
      <div id="jeeFaceFilter" >
        <canvas width="480" height="600" id='jeeFaceFilterCanvas'></canvas>
      </div>
      <router-link v-if="isUser" :to="{ name: 'MobileMenu' }" class="absolute top-4 left-selfie-padding">
        <BaseIcon name= 'arrowLeftWhite' outlineColor="white"/>
      </router-link>
      <router-link v-else :to="{ name: 'SelfieInstruction1' }" class="absolute top-4 left-selfie-padding">
        <BaseIcon name= 'arrowLeftWhite' outlineColor="white"/>
      </router-link>
      <div class="absolute top-3/4 w-screen overflow-auto max-w-2xl ">
        <!-- <button @click="takeSelfie()" type="secondary" class="text-center min-w-max inline-block bg-redDamask bg-opacity-30 border border-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer hover:bg-opacity-50 focus:bg-opacity-50 active:bg-opacity-50 clickebel">Take Selfie</button> -->
        <div class="py-7">
          <div id="filters" class="flex gap-4 translate-x-[40%] snap-mandatory snap-x">
              <button id="filter_element_default" class="filter_element active inline-block w-[4.5rem] h-[3.8rem] border-2 border-borderColor-selfieFilter rounded-full cursor-pointer"><span class="inline-block rounded-full w-14 h-14 p-0.5 bg-white scale-90"></span> </button>
              <!-- <button @click="buttonClick(1)" type="secondary" class="text-center min-w-max inline-block bg-redDamask bg-opacity-30 border border-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer hover:bg-opacity-50 focus:bg-opacity-50 active:bg-opacity-50 clickebel">change2</button> -->
          </div>
        </div>
      </div>
    </div>
    <div id="taken_selfie" class="hidden">
      <div class="absolute top-6 left-selfie-padding z-20 w-[85vw] hidden" id="timelineSuccess" >
        <div class="selfieSystemMessage relative">
          <span class="">{{ $t('selfieInstruction.timelineSaveSuccess') }}</span>
          <BaseIcon name='closeWhite' outline=false class="successCloseButton" @click="closeTimelineSuccessMessage()" /> 
        </div>
      </div>
      <div class="absolute top-6 left-selfie-padding z-20 w-[85vw] hidden" id="downloadSuccess" >
        <div class="selfieSystemMessage relative">
          <span class="">{{ $t('selfieInstruction.downloadSuccess') }}</span>
          <BaseIcon name='closeWhite' outline=false class="successCloseButton" @click="closeDownloadSuccessMessage()" /> 
        </div>
      </div>
      <BaseIcon name='closeWhite' outlineColor="white" @click="openDeletModal()" class="absolute top-4 left-selfie-padding z-10" />   
      <div class="absolute right-selfie-padding top-2/3 flex flex-col gap-6">
        <button @click="shareSelfieModal()" type="secondary" class="text-center min-w-max inline-block text-white font-body text-bodyS font-medium cursor-pointer clickable">
          <BaseIcon name='shareWhite' outline=false padding="p-0" class="mx-auto" /> 
          {{$t('selfieInstruction.photoShare')}}
        </button>
        <button @click="downloadSelfie()" type="secondary" class="download_selfie text-center min-w-max inline-block text-white font-body text-bodyS font-medium cursor-pointer clickable">
          <BaseIcon name='arrowRightWhite' outline=false padding="p-0" class="rotate-90 mx-auto" /> 
          {{$t('selfieInstruction.photoDownload')}}
        </button>
      </div>
      <button v-if="isPin" @click="saveToTimeline()" type="secondary" id="add_to_timeline" class="absolute bottom-selfie-padding left-1/2 -translate-x-1/2 text-center w-2/3 inline-block border border-white rounded-full text-white font-body text-bodyL font-medium cursor-pointer pt-2.5 pb-2 clickable">{{$t('selfieInstruction.photoSave')}}</button>
      <canvas id="taken_selfie_canvas" class=""></canvas>
    </div>
    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 pt-4 bg-halfColonialWhite flex flex-col rounded-lg max-h-popup z-99 hidden" id="popupModalClose">
        <div class="border-b border-b-borderColor">
          <h4 class="max-w-[85%] mx-auto font-heading text-h6 text-black">{{ $t('selfieInstruction.photoDeletTitle') }}</h4>
          <p class="max-w-[85%] mx-auto font-body text-bodyS text-black pt-1.5 pb-4">{{ $t('selfieInstruction.photoDeletDescription') }}</p>
        </div>
        <p class="font-body text-bodyL text-persianRed pt-3 pb-4 border-b border-b-borderColor" @click="deletePicture()">{{ $t('selfieInstruction.photoDeletSure') }}</p>
        <p class="download_selfie font-body text-bodyL text-black pt-3 pb-4 border-b border-b-borderColor" @click="saveCloseModal()">{{ $t('selfieInstruction.photoDownloadSure') }}</p>
        <p class="font-body text-bodyL text-black pt-3 pb-4" @click="closeModals()">{{ $t('selfieInstruction.photoBackToPhoto') }}</p>
    </div>
	  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 pt-4 bg-halfColonialWhite flex flex-col rounded-lg max-h-popup z-99 hidden" id="popupModalShare">
      <div class="border-b border-b-borderColor">
        <h4 class="max-w-[85%] mx-auto font-heading text-h6 text-black">{{ $t('selfieInstruction.photoShareTitle') }}</h4>
        <p class="max-w-[85%] mx-auto font-body text-bodyS text-black pt-1.5 pb-4">{{ $t('selfieInstruction.photoShareDescription') }}</p>
      </div>
      <div class="border-b border-b-borderColor flex flex-row justify-center gap-2 py-2">
        <img src="../../assets/icons/selfieFbShare.svg" alt="facebook" width=40 height=40 @click="sharePicture('fb')">
        <img src="../../assets/icons/selfieMailShare.svg" alt="facebook" width=40 height=40  @click="sharePicture('em', $t('selfieInstruction.photoEmailContent', {input: '#photo_link'}))">
      </div>
      <p class="font-body text-bodyL text-black pt-3 pb-4" @click="closeModals()">{{ $t('selfieInstruction.photoShareBack') }}</p>
    </div>
    <!--<div v-else class="mt-8">
        <div class="mx-auto w-44 rounded-t-full rounded-b h-52 overflow-hidden ">
            <img :src="entity_img" class="object-cover h-full w-full">
        </div>
        <p class="dark:text-redDamask text-redDamask font-body text-bodyL">{{ $t('qrScan.foundText') }}{{ trans_id }}</p>
        <h5 class="font-heading text-h5 text-sepiaBlack dark:text-white" v-html="entity_name"></h5>
        <div class="mt-11 w-48 mx-auto space-y-2">
            <Button :text="$t('qrScan.openBtn')" buttonType="primary" :href="this.entity_link" id="openButton" />
            <Button :text="$t('qrScan.backBtn')" buttonType="secondary" v-on:click="backToRead"/>
        </div>
    </div> -->

    <!-- test
    <input id="scale" type="text" placeholder="scale">
    <input id="posX" type="text" placeholder="posX">
    <input id="posY" type="text" placeholder="posY"> 
    <input id="posZ" type="text" placeholder="posZ"> 
    <input id="rotX" type="text" placeholder="rotX"> 
    <input id="rotY" type="text" placeholder="rotY"> 
    <input id="rotZ" type="text" placeholder="rotZ"> 
    <input id="face_scale" type="text" placeholder="face_scale 1.12">
    <input id="face_posX" type="text" placeholder="face_posX 0">
    <input id="face_posY" type="text" placeholder="face_posY 1.3"> 
    <input id="face_posZ" type="text" placeholder="face_posZ -0.25"> 
    <input id="face_rotX" type="text" placeholder="face_rotX"> 
    <input id="face_rotY" type="text" placeholder="face_rotY"> 
    <input id="face_rotZ" type="text" placeholder="face_rotZ">  -->
</div>
</template>

<script setup>

import("../../../jeeliz/libs/three/v112/three.min.js");
// import * as THREE from "../../../jeeliz/libs/three/v112/three.min.js";
import * as THREE from "../../../jeeliz/libs/three/v112/three.js";
const JEELIZFACEFILTER = require('../../../jeeliz/dist/jeelizFaceFilter.module.js');
// const JEELIZFACEFILTER = require('../../../jeeliz/dist/jeelizFaceFilter.js');
const JeelizResizer = require("../../../jeeliz/helpers/JeelizResizer.js");
import * as JeelizThreeHelper  from "../../../jeeliz/helpers/JeelizThreeHelper.js";
// import {default as JeelizThreeHelper} from '../../../jeeliz/helpers/JeelizThreeHelper.js';
// require("../../../jeeliz/helpers/addDragEventListener.js");
import $ from 'jquery'

// some globalz:
let THREECAMERA = null;

let filters = [];

let imagID = 0;
let imagUrl = '';

let selfieHeight = 0;
let selfieWidth = 0;
let params = {};
let viewIndex = -2;
let isUser = localStorage.getItem('token') ? true : false;
let isPin = localStorage.getItem('user_pin') ? true : false;

//local docker
// let originUrl = window.location['origin'];
//dev
let originUrl = process.env.VUE_APP_AXIOS_URL;

// callback: launched if a face is detected or lost
function detect_callback(isDetected) {
  if (isDetected) {
    console.log('INFO in detect_callback(): DETECTED');
  } else {
    console.log('INFO in detect_callback(): LOST');
  }
}

// build the 3D. called once when Jeeliz Face Filter is OK:
function init_threeScene(spec, params) {

  // GLB object
  const threeStuffs = JeelizThreeHelper.init(spec, detect_callback);

  if (Object.keys(params).length != 0) {
    // CREATE THE ENVMAP:
    const envMap = new THREE.CubeTextureLoader().load( [params.image] );

    // json mesh and loading manager
    let faceMesh = null, filterMesh = null;
    const loadingManager = new THREE.LoadingManager();

    // IMPORT THE GLTF MODEL:
    // from https://threejs.org/examples/#webgl_loader_gltf
    const gltfLoader = new THREE.GLTFLoader();
    gltfLoader.load( params.object, function ( gltf ) {
      gltf.scene.traverse( function ( child ) {
        if ( child.isMesh ) {
          child.material.envMap = envMap;
        }
      } );
      gltf.scene.frustumCulled = false;
      
      // center and scale the object:
      const bbox = new THREE.Box3().expandByObject(gltf.scene);

      // center the model:
      const centerBBox = bbox.getCenter(new THREE.Vector3());
      gltf.scene.position.add(centerBBox.multiplyScalar(-1));
      gltf.scene.position.add(new THREE.Vector3(parseFloat(params.position.x), parseFloat(params.position.y), parseFloat(params.position.z)));

      // // set the object pos
      // const posX = document.getElementById('posX').value;
      // const posY = document.getElementById('posY').value;
      // const posZ = document.getElementById('posZ').value;
      // gltf.scene.position.add(new THREE.Vector3(parseFloat(posX), parseFloat(posY), parseFloat(posZ)));

      // scale the model according to its width:
      const sizeX = bbox.getSize(new THREE.Vector3()).x;

      // const scale = document.getElementById('scale').value;
      // gltf.scene.scale.multiplyScalar(scale / sizeX);

      gltf.scene.scale.multiplyScalar(params.scale / sizeX);

      if (parseFloat(params.rotation.x)) {
        gltf.scene.rotation.x = Math.PI/parseFloat(params.rotation.x);  
      }
      if (parseFloat(params.rotation.y)) {
        gltf.scene.rotation.y = Math.PI/parseFloat(params.rotation.y);  
      }
      if (parseFloat(params.rotation.z)) {
        gltf.scene.rotation.z = Math.PI/parseFloat(params.rotation.z);  
      }
      // const rotX = document.getElementById('rotX').value;
      // const rotY = document.getElementById('rotY').value;
      // const rotZ = document.getElementById('rotZ').value;

      //  if (parseFloat(rotX)) {
      //   gltf.scene.rotation.x = Math.PI/parseFloat(rotX);  
      // }
      // if (parseFloat(rotY)) {
      //   gltf.scene.rotation.y = Math.PI/parseFloat(rotY);  
      // }
      // if (parseFloat(rotZ)) {
      //   gltf.scene.rotation.z = Math.PI/parseFloat(rotZ);  
      // }

      // dispatch the model:
      threeStuffs.faceObject.add(gltf.scene);
    } ); //end gltfLoader.load callback

  // // JSON object
  //   const threeStuffs = JeelizThreeHelper.init(spec, detect_callback);

  // // CREATE THE HELMET MESH AND ADD IT TO THE SCENE:
  //   const HELMETOBJ3D = new THREE.Object3D();
  //   const helmetLoader = new THREE.BufferGeometryLoader(loadingManager);
  //   const filterLoader = new THREE.BufferGeometryLoader(loadingManager);
  //   filterLoader.load(
  //     params.object,
  //     (filterGeometry) => {
  //       const filterMaterial = new THREE.MeshStandardMaterial({
  //         // map: new THREE.TextureLoader().load(params.image),
  //         map: new THREE.TextureLoader().load('http://localhost:8080/wp-content/uploads/2022/08/diffuse_helmet-scaled.jpg'),
  //         reflectionRatio: 1,
  //         shininess: 50
  //       });

  //       filterMesh = new THREE.Mesh(filterGeometry, filterMaterial);
  //       filterMesh.scale.multiplyScalar(params.scale);
  //       filterMesh.position.y += parseFloat(params.position.y);
  //       filterMesh.position.z += parseFloat(params.position.z);
  //       filterMesh.rotation.x += parseFloat(params.position.x);
  //       filterMesh.frustumCulled = false;
  //     }
  //   );

    if(params.face.behind) {

      // CREATE THE MASK FRONT OF FACE
      const maskLoader = new THREE.BufferGeometryLoader(loadingManager);
      /*
        faceLowPolyEyesEarsFill.json has been exported from dev/faceLowPolyEyesEarsFill.blend
        using THREE.JS blender exporter with Blender v2.76
      */
      maskLoader.load(originUrl + '/wp-content/themes/szmm/jeeliz/faceLowPoly/faceLowPolyEyesEarsFill2.json', function (maskBufferGeometry) {
        const vertexShaderSource = 'uniform mat2 videoTransformMat2;\n\
        varying vec2 vUVvideo;\n\
        varying float vY, vNormalDotZ;\n\
        const float THETAHEAD = 0.25;\n\
        \n\
        void main() {\n\
          vec4 mvPosition = modelViewMatrix * vec4( position, 1.0);\n\
          vec4 projectedPosition = projectionMatrix * mvPosition;\n\
          gl_Position = projectedPosition;\n\
          \n\
          // compute UV coordinates on the video texture:\n\
          vec4 mvPosition0 = modelViewMatrix * vec4( position, 1.0 );\n\
          vec4 projectedPosition0 = projectionMatrix * mvPosition0;\n\
          vUVvideo = vec2(0.5,0.5) + videoTransformMat2 * projectedPosition0.xy/projectedPosition0.w;\n\
          vY = position.y*cos(THETAHEAD)-position.z*sin(THETAHEAD);\n\
          vec3 normalView = vec3(modelViewMatrix * vec4(normal,0.));\n\
          vNormalDotZ = pow(abs(normalView.z), 1.5);\n\
        }';

        // It was inside the fragment main last 3 lines instead of last 3 line
        // float darkenCoeff = smoothstep(-0.15, 0.05, vY);\n\
        // float borderCoeff = smoothstep(0.0, 0.55, vNormalDotZ);\n\
        // gl_FragColor = vec4(videoColor * (1.-darkenCoeff), borderCoeff );\n\
        const fragmentShaderSource = "precision lowp float;\n\
        uniform sampler2D samplerVideo;\n\
        varying vec2 vUVvideo;\n\
        varying float vY, vNormalDotZ;\n\
        void main() {\n\
          vec3 videoColor = texture2D(samplerVideo, vUVvideo).rgb;\n\
          float borderCoeff = smoothstep(0.0, 0.05, vNormalDotZ);\n\
          gl_FragColor = vec4(videoColor, borderCoeff );\n\
        }";

        const mat = new THREE.ShaderMaterial({
          vertexShader: vertexShaderSource,
          fragmentShader: fragmentShaderSource,
          transparent: true,
          flatShading: false,
          uniforms: {
            samplerVideo:{ value: JeelizThreeHelper.get_threeVideoTexture() },
            videoTransformMat2: {value: spec.videoTransformMat2}
          },
          transparent: true
        });
        maskBufferGeometry.computeVertexNormals();
        faceMesh = new THREE.Mesh(maskBufferGeometry, mat);
        faceMesh.renderOrder = -10000;
        faceMesh.frustumCulled = false;

        // const face_scale = document.getElementById('face_scale').value;
        // const face_rotX = document.getElementById('face_rotX').value;
        // const face_rotY = document.getElementById('face_rotY').value;
        // const face_rotZ = document.getElementById('face_rotZ').value;
        // const face_posX = document.getElementById('face_posX').value;
        // const face_posY = document.getElementById('face_posY').value;
        // const face_posZ = document.getElementById('face_posZ').value;

        // faceMesh.scale.multiplyScalar(face_scale);
        // faceMesh.position.set(parseFloat(face_posX), parseFloat(face_posY), parseFloat(face_posZ));
        // if (parseFloat(face_rotX)) {
        //   faceMesh.rotation.x = Math.PI/parseFloat(face_rotX);  
        // }
        // if (parseFloat(face_rotY)) {
        //   faceMesh.rotation.y = Math.PI/parseFloat(face_rotY);  
        // }
        // if (parseFloat(face_rotZ)) {
        //   faceMesh.rotation.z = Math.PI/parseFloat(face_rotZ);  
        // }

        faceMesh.scale.multiplyScalar(params.face.scale);
        faceMesh.position.set(parseFloat(params.face.position.x), parseFloat(params.face.position.y), parseFloat(params.face.position.z));
        if (parseFloat(params.face.rotation.x)) {
          faceMesh.rotation.x = Math.PI/parseFloat(params.face.rotation.x);  
        }
        if (parseFloat(params.face.rotation.y)) {
          faceMesh.rotation.y = Math.PI/parseFloat(params.face.rotation.y);  
        }
        if (parseFloat(params.face.rotation.z)) {
          faceMesh.rotation.z = Math.PI/parseFloat(params.face.rotation.z);  
        }      
        threeStuffs.faceObject.add(faceMesh);
      });
    }
  } else {
    viewIndex = -1;
  }
  // loadingManager.onLoad = () => {
  //   HELMETOBJ3D.add(filterMesh);
  //   HELMETOBJ3D.add(faceMesh);

  //   threeStuffs.faceObject.add(HELMETOBJ3D);
  //   console.log('add meshs');
  // }
  //END JSON

  // CREATE THE CAMERA:
  THREECAMERA = JeelizThreeHelper.create_camera();
  // console.log(THREECAMERA);
  // THREECAMERA.fov = 45;
  // THREECAMERA.aspect = 480/60;
  // THREECAMERA.zoom = 0.2;
  // THREECAMERA.focus = 100;
  // THREECAMERA.view.height = 600;
  // THREECAMERA.view.width = 480;
  // THREECAMERA.view.fullHeight = 600;
  // THREECAMERA.view.fullWidth = 480;

  // THREECAMERA.scale.y = -1;
  // console.log(THREECAMERA);

  // CREATE THE LIGHTS:
  const ambientLight = new THREE.AmbientLight( 0xffffff ); // soft white light
  threeStuffs.scene.add(ambientLight);

  const dirLight = new THREE.DirectionalLight( 0xffffff );
  dirLight.position.set( 100, 1000, 100 );
  threeStuffs.scene.add(dirLight);

  // document.getElementById('selfie_filters').classList.add('flex');
	// document.getElementById('selfie_filters').classList.add('flex-col');
} // end init_threeScene()


// Entry point:
function main(){
  // JEELIZFACEFILTER.set_videoOrientation(-90);
  var request = $.ajax({
      type: 'GET',
      url: originUrl + '/wp-json/wp/v2/digital_entityies/selfie?type=',
      data: '',
      contentType: 'json'
  });
  request.done(function(res) {
      // _callback(res);
      filters = res;
      for(const index in res) {
        // $('#filters')[0].innerHTML += '<button onClick=buttonClick('+index+') type="secondary" id="filter_element_id_'+index+'" class="filter_element text-center min-w-max inline-block bg-redDamask bg-opacity-30 border border-redDamask rounded-full text-white font-body text-bodyL font-medium cursor-pointer hover:bg-opacity-50 focus:bg-opacity-50 active:bg-opacity-50 clickebel"><img src="' + res[index]['selfie_thumbnail'] + '" width=100 height=100 /></button>';
        $('#filters')[0].innerHTML += '<button id="filter_element_id_'+index+'" class="filter_element snap-center scroll-ml-6 text-center min-w-max inline-block border-2 border-borderColor-selfieFilter rounded-full text-white font-body text-bodyL font-medium cursor-pointer"><img src="' + res[index]['selfie_thumbnail'] + '" width=56px height=56px class="rounded-full w-14 h-14 p-0.5" /></button>';
      }
      document.getElementById("filter_element_default").addEventListener("click", function() {
        buttonClick(-1);
      });
      for(const index in res) {

        document.getElementById("filter_element_id_"+index).addEventListener("click", function() {
          buttonClick(index);
        });
        }

      // JeelizResizer.size_canvas({
      //   canvasId: 'jeeFaceFilterCanvas',
      //   callback: function(isError, bestVideoSettings){
      //     init_faceFilter(bestVideoSettings, params);
      //   }
      // })
      JeelizResizer.size_canvas({
        canvasId: 'jeeFaceFilterCanvas',
        // isFullScreen : true,
        callback     : (isError, videoSettings) => {
          init_faceFilter(videoSettings, params);
        },
        onResize: () => {
          if (THREECAMERA) {
            THREECAMERA.aspect = 0.75
            THREECAMERA.updateProjectionMatrix()
          }
        }
      });
  });
  request.fail(function(jqXHR, textStatus) {
      console.error(jqXHR)
      console.error(textStatus)
      // _callback({ err: true, message: "Request failed: " + textStatus });
  });
}


function init_faceFilter(videoSettings, params){
  if (window.innerWidth >= 1080) {
    selfieWidth = 1080;
  } else if (window.innerWidth >= 768) {
    selfieWidth = 768;
  } else if (window.innerWidth >= 720) {
    selfieWidth = 720;
  } else if (window.innerWidth >= 600) {
    selfieWidth = 600;
  } else {
    selfieWidth = 480;
  }

  if (selfieWidth == 600) {
    selfieHeight = 800;
  } else if (selfieWidth == 720) {
    selfieHeight = 960;
  } else if (selfieWidth == 768) {
    selfieHeight = 1024;
  } else if (selfieWidth == 1080) {
    selfieHeight = 1920;
  } else {
    selfieHeight = 640;
  }

  let myVideoSetting = {idealWidth: selfieWidth, idealHeight: selfieHeight}
  
  JEELIZFACEFILTER.init({
    canvasId: 'jeeFaceFilterCanvas',
    NNCPath: originUrl + '/wp-content/themes/szmm/jeeliz/neuralNets/NN_DEFAULT.json', // root of NN_DEFAULT.json file
    videoSettings: videoSettings,
    videoSettings: Object.assign(videoSettings, {
        flipX       : true,
        idealWidth  : videoSettings.idealHeight,
        idealHeight : videoSettings.idealWidth,
      }),
    callbackReady: function (errCode, spec) {
      if (errCode) {
        console.log('AN ERROR HAPPENS. SORRY BRO :( . ERR =', errCode);
        buttonClick(-1);
        return;
      }

      console.log('INFO: JEELIZFACEFILTER IS READY');

      let transforme = (window.innerHeight/selfieHeight * selfieWidth - window.innerWidth) / -2;
      // let transforme = (selfieWidth - window.innerWidth) / -2;
      document.getElementById("jeeFaceFilterCanvas").style.transform="translateX(" + (window.innerWidth - selfieWidth)/2 + "px) scale(" + (window.innerWidth/selfieWidth ) + ")";

      spec.canvasElement.height = selfieHeight;
      spec.canvasElement.width = selfieWidth;
      init_threeScene(spec, params);
    },

    // called at each render iteration (drawing loop)
    callbackTrack: function (detectState) {
      JeelizThreeHelper.render(detectState, THREECAMERA);
    }
  }); // end JEELIZFACEFILTER.init call
}

main();

function activateFilter(index) {
  document.querySelectorAll('.filter_element').forEach(element => {
     element.classList.contains('active') ? element.classList.remove('active'): '';
  });
  if (index >= 0) {
    document.querySelector(`#filter_element_id_${index}`).classList.add('active');
  } else {
    document.getElementById("filter_element_default").classList.add('active');
  }
}

function buttonClick(index) {
    // console.log(index);
    if (index == viewIndex) {
      takeSelfie();
    } else {
      viewIndex = index;
      activateFilter(index);
      if (index >= 0 ) {
        params = {
          "object": originUrl + '/wp-content' + filters[index]['selfie_object'].split('wp-content')[1],
          "image": filters[index]['selfie_image'],
          "scale": filters[index]['selfie_scale'],
          "position": {
            "x": filters[index]['selfie_position_x'],
            "y": filters[index]['selfie_position_y'],
            "z": filters[index]['selfie_position_z'],
          },
          "rotation": {
            "x": filters[index]['selfie_rotation_x'],
            "y": filters[index]['selfie_rotation_y'],
            "z": filters[index]['selfie_rotation_z'],
          },
          "face": {
            "behind": filters[index]['behind_face'],
            "scale" : filters[index]['selfie_face_scale'],
            "position": {
              "x": filters[index]['selfie_face_position_x'],
              "y": filters[index]['selfie_face_position_y'],
              "z": filters[index]['selfie_face_position_z'],
            },
            "rotation": {
              "x": filters[index]['selfie_face_rotation_x'],
              "y": filters[index]['selfie_face_rotation_y'],
              "z": filters[index]['selfie_face_rotation_z'],
            }
          },
        };
      } else {
        params = {};
      }
      JEELIZFACEFILTER.destroy().
      then(function() {
        $('#jeeFaceFilter')[0].innerHTML = '<canvas width="480" height="600" id="jeeFaceFilterCanvas"></canvas>';
        const bestVideoSettings = {'idealWidth': 480, 'idealHeight': 600};
        init_faceFilter(bestVideoSettings, params);
        // document.getElementById('selfie_filters').classList.remove('flex');
        // document.getElementById('selfie_filters').classList.remove('flex-col');
      }, function() {
      });
    }
}

function takeSelfie() {
  imagUrl = '';
  imagID = 0;
	const canvas = document.getElementById('jeeFaceFilterCanvas');
	var image = new Image();
	image.id = "canvasimageprev";
	image.src = canvas.toDataURL();
  image.width = selfieWidth;
  image.height = selfieHeight;
  image.maxWidth = 'auto';
	document.getElementById('taken_selfie').appendChild(image);

  // image.addEventListener("load", (e) => {
    // var savebleImage = new Image();
    // savebleImage.id = "canvasimage";
    // let transforme = (window.innerHeight/selfieHeight * selfieWidth - window.innerWidth) / 2;
    // if (transforme > 0) {
    //   var selfie = document.getElementById("taken_selfie_canvas"); 
    //   const ctx = selfie.getContext("2d");
    //   ctx.canvas.width = window.innerWidth;
    //   ctx.canvas.height = window.innerHeight;

    //   if (selfieWidth > window.innerWidth) {
    //     // ctx.drawImage(image, Math.pow(window.innerWidth/selfieWidth,3)*transforme, 0, window.innerWidth-2*transforme*Math.pow(window.innerWidth/selfieWidth,5), selfieHeight, 0, 0, selfieWidth, selfieHeight);
    //     // ctx.drawImage(image, Math.sqrt(window.innerWidth/selfieWidth)*transforme, 0, window.innerWidth-2*transforme*Math.pow(window.innerWidth/selfieWidth,7), selfieHeight, 0, 0, selfieWidth, selfieHeight);
    //     // ctx.drawImage(image, 0.75*transforme, 0, window.innerWidth-Math.pow(window.innerWidth/selfieWidth,2.5)*1.5*transforme, selfieHeight, 0, 0, selfieWidth, selfieHeight);
    //     ctx.drawImage(image, 0.75*transforme, 0, window.innerWidth-Math.pow(window.innerWidth/selfieWidth,2.5)*1.5*transforme, selfieHeight, 0, 0, window.innerWidth, window.innerHeight);
    //   } else {
    //     // ctx.drawImage(image, transforme, 0, window.innerWidth-2*transforme, selfieHeight, 0, 0, window.innerWidth, selfieHeight);
    //     ctx.drawImage(image, transforme, 0, window.innerWidth-2*transforme, selfieHeight, 0, 0, window.innerWidth, window.innerHeight);
    //   }
    //   savebleImage.src = selfie.toDataURL();
    // } else {
      // savebleImage.src = canvas.toDataURL();
    // }

	  // document.getElementById('taken_selfie').appendChild(savebleImage);
  // });

	document.getElementById('taken_selfie').classList.remove('hidden');
	document.getElementById('selfie_filters').classList.add('hidden');
  const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
  if (authUser && authUser.data.user.id && isPin) {
	  document.getElementById('add_to_timeline').classList.remove('hidden');
  }
}

function downloadSelfie() {
	const data = $('#canvasimageprev').attr('src');
	// const data = $('#canvasimage').attr('src');
	const today = new Date();
	const date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
	const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
	const documentName = 'selfie_' + date + '_' + time;
	saveBase64AsFile(data, documentName);
  document.getElementById('downloadSuccess').classList.remove('hidden');
}

function saveBase64AsFile(base64, fileName) {
    var link = document.createElement("a");
    document.getElementById('taken_selfie').appendChild(link); // for Firefox
    link.setAttribute("href", base64);
    link.setAttribute("download", fileName);
    link.click();
}

function closeModals() {
    document.getElementById('popupModalClose').classList.add('hidden');
    document.getElementById('popupModalShare').classList.add('hidden');
} 
  
function openDeletModal() {
    document.getElementById('popupModalClose').classList.remove('hidden');
}

function deletePicture() {
    closeModals();
    // document.getElementById('taken_selfie').removeChild(document.getElementById('canvasimage'));
    document.getElementById('taken_selfie').removeChild(document.getElementById('canvasimageprev'));
    document.getElementById('selfie_filters').classList.remove('hidden');
    document.getElementById('taken_selfie').classList.add('hidden');
}

function saveCloseModal() {
	closeModals();
	downloadSelfie();
}

function closeTimelineSuccessMessage() {
  document.getElementById('timelineSuccess').classList.add('hidden');
}

function closeDownloadSuccessMessage() {
  document.getElementById('downloadSuccess').classList.add('hidden');
}

function sharePicture(where, text = '') {
  // const t = $('#canvasimage').attr('alt');
  // let data = $('#canvasimage').attr('src');
  const t = $('#canvasimageprev').attr('alt');
  let data = $('#canvasimageprev').attr('src');
  var base64ImageContent = data.replace(/^data:image\/(png|jpg);base64,/, "");
  if (!imagID && !imagUrl) {
    $.ajax({ 
      type: "POST", 
      url: process.env.VUE_APP_AXIOS_URL + `/wp-json/wp/v2/selfie`,
      // dataType: 'text',
      data: {
        image_data : data
      }, 
      success: function(result){
        if (result['id']) {
          imagID = result['id'];
        }
        if (result['selfie_url']) {
          imagUrl = result['selfie_url']
          if (where == 'fb') {
            window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(imagUrl),'sharer','toolbar=0,status=0,width=626,height=436');
          } else if (where == 'em') {
            var link = document.createElement("a");
            document.getElementById('taken_selfie').appendChild(link); // for Firefox
            const emailContent =  text.replace("#photo_link", imagUrl);
            link.setAttribute("href", 'mailto:?subject=Selfie&body='  + encodeURIComponent(emailContent));
            link.click();
          }
        }
      }
    });
  } else {
    if (where == 'fb') {
      window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(imagUrl),'sharer','toolbar=0,status=0,width=626,height=436');
    } else if (where == 'em') {
      var link = document.createElement("a");
      document.getElementById('taken_selfie').appendChild(link); // for Firefox
      const emailContent =  text.replace("#photo_link", imagUrl);
      link.setAttribute("href", 'mailto:?subject=Selfie&body='  + encodeURIComponent(emailContent));
      link.click();
    }
  }
}

function shareSelfieModal() {
  document.getElementById('popupModalShare').classList.remove('hidden');
}

function base64ToBlob(base64, mime) 
{
    mime = mime || '';
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];

    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: mime});
}

function saveToTimeline() {
  // let data = $('#canvasimage').attr('src');
  let data = $('#canvasimageprev').attr('src');
  const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
  $.ajax({ 
    type: "POST", 
    url: process.env.VUE_APP_AXIOS_URL + `/wp-json/wp/v2/selfieToTimeline`,
    // dataType: 'text',
    data: {
      image_data : imagID ? '' : data,
      image_id: imagID,
      user_id: authUser.data.user.id,
    },
    success: function(result){
      if (result['id']) {
        imagID = result['id'];
      }
      if (result['selfie_url']) {
        imagUrl = result['selfie_url'];
      }
      // console.log(result['code']);
      // console.log(result['text']);
	    document.getElementById('add_to_timeline').classList.add('hidden');
      document.getElementById('timelineSuccess').classList.remove('hidden');
    }
  });
}

import Button from '@/components/Button.vue';
import BaseIcon from "@/components/BaseIcon.vue";

// export default {
//     name: 'TakeSelfie',
//     components: {
//         // QrcodeStream,
//         Button,
//         // SystemMessage,
//         BaseIcon
//     },
//     data() {
//         return {
//             found: false,
//             error: '',
//             language: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
//             baseURL: process.env.VUE_APP_AXIOS_URL,
//             name: window.localStorage.name,
//         }
//     },
//     mounted() {
//         this.$i18n.locale = this.language;
//     },
//     methods: {
//         change() {
//             console.log('change');
//         }
//     },
//     computed: {
//         src() {
//         if (window.localStorage.theme == 'light') {
//             return require("../../assets/Logo-dark.png");
//         } else {
//             return require("../../assets/Logo-light.png")
//         }
//         }
//     }
// }
</script>

<style>
#openButton > a {
    width: 100%;
}

#canvasimage {
    height: 100vh;
    width: auto;
}

.selfieSystemMessage {
  background-color: rgba(105, 179, 76, 1);
  border-radius: 4px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .selfieSystemMessage > div {
  padding: 0;
  height: 33px;
  top: 0;
  z-index: 30;
} */

.selfieSystemMessage > span {
  font-family: 'Epilogue';
  font-size: 10px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.2;
  color: white;
  margin-left: -50%;
}

.selfieSystemMessage > .successCloseButton {
  position: absolute;
  right: 0;
  top: 0;
}

#canvasimageprev {
    width: 100vw;
}
#taken_selfie_canvas {
    display: hidden;
    height: 0;
}

.filter_element.active {
  border-color: white;
  scale: 1.2;
}

</style>
// 