<template>
  <div>
    Gallery
  </div>
</template>

<script>

export default {
  name: 'SelfieMobileGallery',
}
</script>
